/**
 * @prettier
 */

const TX_TYPES = {
	REGULAR: "REGULAR",
	FEE_BUMP: "FEE_BUMP",
};

export default TX_TYPES;
