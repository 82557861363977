/** @format */
import React from "react";

export const ImportMark = ({ width = 32, height = width, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 725 1024"
    aria-labelledby="importMarkTitle"
    role="img"
  >
    <title id="importMarkTitle">Import Public Key via Freighter Button</title>
    <path d="M651.785 737.562c-17.856-16.439-38.345-32.879-60.979-49.003-26.419-86.861-97.335-154.5-186.169-176.836v-120.361h109.454v-126.917h-109.454v-48.256h174.051v-126.916h-174.051v-89.273h-127.268v511.723c-15.352 3.866-30.168 9.075-44.3 15.522-25.265-5.828-49.481-10.078-72.177-12.553-106.071-11.575-142.962 18.082-155.252 44.988-12.29 26.923-10.47 74.155 67.916 146.325 3.555 3.285 7.237 6.554 10.984 9.822-2.912 15.543-4.475 31.578-4.475 47.957 0 143.484 117.055 260.215 260.937 260.215 82.304 0 155.808-38.199 203.663-97.707 6.703 0.981 13.316 1.877 19.763 2.581 16.209 1.775 30.788 2.586 43.934 2.586 72.819 0 100.911-24.768 111.317-47.573 12.314-26.923 10.492-74.155-67.895-146.325zM341.003 630.502c73.697 0 133.668 59.81 133.668 133.303 0 37.619-15.735 71.633-40.981 95.889-36.975-11.891-75.365-26.752-113.82-44.198-40.060-18.189-77.937-38.157-112.323-59.081 3.875-70.076 62.242-125.914 133.455-125.914zM41.225 575.842c7.023-15.373 34.001-24.576 76.288-24.576 18.927 0 40.938 1.856 65.561 5.807-39.054 29.658-69.501 70.016-86.865 116.689-43.079-40.653-64.105-77.956-54.984-97.92zM341.003 897.084c-59.116 0-109.368-38.477-126.947-91.644 28.263 16.013 58.324 31.326 89.627 45.525 29.74 13.491 59.48 25.493 88.664 35.87-15.823 6.596-33.166 10.249-51.344 10.249zM684.117 867.725c-9.357 20.518-53.444 29.103-115.042 22.421 20.941-37.431 32.909-80.521 32.909-126.362 0-6.153-0.235-12.237-0.644-18.257 8.457 6.874 16.465 13.773 23.94 20.668 45.67 42.086 68.215 80.99 58.837 101.53z"></path>
  </svg>
);
